import React, { Component } from 'react';
import { Card, Div, Avatar } from '@vkontakte/vkui';

class HolidaysList extends Component {
  state = {
    holidays: []
  }

  componentDidMount() {
    fetch('holidays.json')
      .then(response => response.json())
      .then(data => this.setState({ holidays: data }));
  }

  render() {
    const { holidays } = this.state;

    return (
      <Div>
        {holidays.map(holiday => (
          <Card
            key={holiday.id_cat}
            mode="outline"
            style={{ marginTop: '16px', marginBottom: '16px' }}
          >
            <Div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={holiday.img_actor} size={72} mode="image" />
              <Div style={{ marginLeft: 12 }}>
                <h3>{holiday.name_actor}</h3>
                <p>{holiday.date_actor}</p>
              </Div>
            </Div>
          </Card>
        ))}
      </Div>
    );
  }
}

export default HolidaysList;
