import React from 'react';
import bridge from '@vkontakte/vk-bridge';
import {
  View,
  ScreenSpinner,
  AdaptivityProvider,
  AppRoot,
  ConfigProvider,
  SplitLayout,
  SplitCol,
  Panel,
  PanelHeader,
  PanelHeaderContent
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import HolidaysList from './HolidaysList';

import Home from './panels/Home';
import Persik from './panels/Persik';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheme: "bright_light"
    };
  }

  componentDidMount() {
    bridge.subscribe(({ detail: { type, data }}) => {
      if (type === 'VKWebAppUpdateConfig') {
        const schemeAttribute = document.createAttribute('scheme');
        schemeAttribute.value = data.scheme ? data.scheme : 'bright_light';
        this.setState({ scheme: schemeAttribute.value });
        document.body.attributes.setNamedItem(schemeAttribute);
      }
    });

    bridge.send('VKWebAppInit');
  }

  render() {
    const { scheme } = this.state;

    return (
      <AdaptivityProvider>
        <AppRoot>
          <ConfigProvider scheme={scheme}>
            <SplitLayout>
              <SplitCol>
                <View activePanel="main">
                  <Panel id="main">
                    <PanelHeader>
                      <PanelHeaderContent>
                        Праздники
                      </PanelHeaderContent>
                    </PanelHeader>
                    <HolidaysList />
                  </Panel>
                </View>
              </SplitCol>
            </SplitLayout>
          </ConfigProvider>
        </AppRoot>
      </AdaptivityProvider>
    );
  }
}

export default App;
